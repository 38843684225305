import {useState, useCallback, useContext} from 'react'
import {AuthContext} from "../context/AuthContext";
import {openNotification} from "../components/Notification";
import axios from "axios";
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
export const useHttp = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const auth = useContext(AuthContext);
    const request = useCallback(async (url, method = 'GET', body = null, headers = {Authorization: `Bearer ${auth.token}`}) => {
        setLoading(true);
        try {
            if (body) {
                body = JSON.stringify(body);
                headers['Content-Type'] = 'application/json'
            }
            const response = await fetch(url, {method, body, headers});
            if (response.status === 302) {
                window.location.href = "https://www.google.com"
            }
            const data = await response.json();
            if (response.status === 404) {
                openNotification('warning', 'не найдено');
                return
            }

            if (response.status === 401) {
                auth.logout();
            }
            if (!response.ok) {
                setLoading(false);
                throw data
            }

            setLoading(false);
            if (data && data.message) {
                openNotification('success', data.message || 'Отлично');
            }
            return data
        } catch (e) {
            setLoading(false);
            const isString = typeof e.message === "string";
            setError(isString ? e.message : 'Что-то пошло не так');
            openNotification('error', isString ? e.message : 'Что-то пошло не так');
            //throw e
        }
    }, []);

    const request1 = useCallback(async (url, method, body) => {
        const headers = {
            ContentType: 'application/json',
            Authorization: `Bearer ${auth.token}`,
            'X-Requested-With': 'XMLHttpRequest'
        };
        const client = axios.create({
            headers,
            method: method || 'GET'
        });

        try {
            setLoading(true)
            const res = await client.request({url, data: body});

            setLoading(false);
            return res.data;
        } catch (err) {
            setLoading(false);
            console.log(err.response)

            if(!err.response) {
                openNotification('error', 'Что-то пошло не так');
                return
            }

            if(err.response.headers.location) {
                window.location = err.response.headers.location;
                return
            }

            if (err.response.status === 401) {
                auth.logout();
                return
            }

            if (err.response.status === 404) {
                openNotification('warning', 'не найдено');
                return
            }

            const isString = typeof err.response.data.message === "string";
            openNotification('error', isString ? err.response.data.message : 'Что-то пошло не так');
            return Promise.reject(err.response.data.message);
        }
    }, [])

    const clearError = useCallback(() => setError(null), []);

    return {loading, request: request1, error, clearError}
};
