import React, {useEffect, useState} from 'react';
import {useHttp} from "../../hooks/http.hook";
import {Divider, Skeleton, Timeline} from "antd";
import moment from "moment";
import _ from "lodash";
import {statuses} from "../../utils/locales";


const OrderHistory = ({orderId}) => {
    const {request, loading} = useHttp();
    const [orderLog, setOrderLog] = useState([]);

    useEffect(() => {
        async function fetchHistory() {
            const hist = await request('/api/orderLog/read', 'POST', {orderId});
            setOrderLog(hist)
        }

        fetchHistory();
    }, [orderId]);

    return (
        <div>
            {loading ? (
                <Skeleton active={true}/>
            ) : (
                <Timeline mode={"left"}>
                    {(orderLog.history || []).map((item, index) => {
                        const labelIndex = _.findIndex(statuses, (o) => {
                            return item.status === o.value
                        });
                        return (
                            <Timeline.Item
                                color={statuses[labelIndex] ? statuses[labelIndex].color : null}
                                label={moment(item.date).format('DD-MM-YYYY')}
                            >
                                <RenderHistoryContent
                                    key={index}
                                    comment={item.comment}
                                    date={item.date}
                                    executor={item.executor}
                                    manager={item.manager}
                                    outsource={item.outsource}
                                    price={item.price}
                                    status={statuses[labelIndex] ? statuses[labelIndex].label : 'Без статуса'}
                                    otherExpenses={item.otherExpenses}
                                    otherExpensesComment={item.otherExpensesComment}
                                    usedDetails={item.usedDetails}
                                />
                            </Timeline.Item>
                        )
                    })}
                </Timeline>
            )}
        </div>
    )
};

export default OrderHistory;


const RenderHistoryContent = (props) => {
    const {manager, executor, price, status, comment, otherExpenses, otherExpensesComment, usedDetails, outsource} = props;
    return (
        <>
            <span><b>Статус:</b> {status}</span>
            <Divider style={{margin: 0}}/>
            {manager &&
            <>
                <span><b>Менеджер:</b> {manager.username}</span>
                <Divider style={{margin: 0}}/>
            </>}
            {executor &&
            <>
                <span><b>Мастер:</b> {executor.name}</span>
                <Divider style={{margin: 0}}/>
            </>}
            {outsource && (
                <>
                    <span><b>Сервис:</b> {outsource.name}</span>
                    <Divider style={{margin: 0}}/>
                </>
            )}
            {price &&
            <>
                <span><b>Цена:</b> {price}</span>
                <Divider style={{margin: 0}}/>
            </>}
            {comment && <span><b>Комментарий:</b> {comment ? comment : 'Комментария нет'}</span>}
            <Divider style={{margin: 0}}/>
            {status === 'Готов' && (
                <>
                    {otherExpenses &&
                    <>
                        <span><b>Затраты:</b> {otherExpenses}</span>
                        <Divider style={{margin: 0}}/>
                        <span><b>Описание затрат:</b> {otherExpensesComment}</span>
                        <Divider style={{margin: 0}}/>
                    </>}
                    {usedDetails && (
                        <>
                            <span><b>Исп. запчасти:</b> {usedDetails.map((detail, i) => {
                                return (
                                    <p>{i + 1}. {detail.name}</p>
                                )
                            })} </span>
                        </>
                    )}
                </>
            )}
        </>
    )
};