import React, {useEffect, useState} from "react";
import {Tabs} from 'antd';

import {useHttp} from "../../../hooks/http.hook";
import Profile from "./Profile";
import Setup from "./Setup";
import "./styles.css"
import Mailing from "./Mailing";

const {TabPane} = Tabs;

const MainSms = () => {
    const {request} = useHttp();
    const [isConnected, setIsConnected] = useState(false);
    const [isReady, setIsReady] = useState(false);

    useEffect(() => {
        async function checkForConnection() {
            try {
                const response = await request('/api/sms-service/checkForConnection', 'POST', {})
                setIsConnected(Boolean(response.status))
                setIsReady(true)
            } catch (e) {
                console.log(e)
                setIsConnected(false)
                setIsReady(true)
            }
        }

        checkForConnection()
    }, []);

    async function updateProfile(newCredentials) {
        try {
            await request('/api/sms-service/updateProfile', 'POST', newCredentials)
        } catch (e) {
            throw e;
        }
    }

    async function removeProfile() {
        try {
            await request('/api/sms-service/removeProfile', 'POST', {})
            setIsConnected(false)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <>
            {isReady ? (
                isConnected ? (
                    <Tabs tabPosition={'left'} style={{height: '100%'}}>
                        <TabPane tab={'Профиль'} key={1}>
                            <Profile
                                updateProfile={updateProfile}
                                removeProfile={removeProfile}
                            />
                        </TabPane>
                        <TabPane tab={'Рассылки'} key={2}>
                            <Mailing/>
                        </TabPane>
                        <TabPane tab={'Группы'} key={3}>
                            <Mailing/>
                        </TabPane>
                    </Tabs>
                ) : (
                    <Setup setIsConnected={setIsConnected}/>
                )
            ) : <></>}


        </>
    )
};

export default MainSms
