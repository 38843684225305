import React from "react";
import {
    AliwangwangOutlined,
    AppstoreOutlined,
    BarChartOutlined, BuildFilled, CloudOutlined,
    DollarCircleFilled,
    ExceptionOutlined, LaptopOutlined,
    LineChartOutlined, MoneyCollectFilled,
    SettingFilled, ShopOutlined, ShoppingCartOutlined,
    TeamOutlined, UserSwitchOutlined, MessageFilled, CodeFilled
} from "@ant-design/icons";

const adminMenu = [
    {
        title: "Главная",
        url: "/",
        icon: <BarChartOutlined/>
    },
    {
        title: "Финансы",
        url: "/finances",
        icon: <DollarCircleFilled/>
    },
    {
        title: "Аналитика",
        url: "/analytics",
        icon: <LineChartOutlined/>
    },
    {
        title: "Админ",
        icon: <SettingFilled/>,
        sub: [
            {
                title: "Пользователи",
                url: "/users",
                icon: <TeamOutlined/>
            },
            {
                title: "Исполнители",
                url: "/executors",
                icon: <ExceptionOutlined/>
            },
            {
                title: "Офисы",
                url: "/offices",
                icon: <BuildFilled/>
            }
        ]
    },
    {
        title: "Система",
        icon: <CodeFilled/>,
        sub: [
            {
                title: "Main SMS",
                url: "/system/main-sms",
                icon: <MessageFilled/>
            }
        ]
    },
    {
        title: "Менеджмент",
        icon: <AppstoreOutlined/>,
        sub: [
            {
                title: "Заказы",
                url: "/orders",
                icon: <ShoppingCartOutlined/>
            },
            {
                title: "Оплаченные",
                url: "/payed-orders",
                icon: <MoneyCollectFilled/>
            },
            {
                title: "Клиенты",
                url: "/clients",
                icon: <UserSwitchOutlined/>
            },
            {
                title: "Устройства",
                url: "/devices",
                icon: <LaptopOutlined/>
            },

        ]
    },
    {
        title: "Прочее",
        icon: <CloudOutlined/>,
        sub: [
            {
                title: "Откуда Узнали",
                url: "/whereKnown",
                icon: <AliwangwangOutlined/>
            },
            {
                title: "Внешний вид устройства",
                url: "/deviceAppearance",
                icon: <AliwangwangOutlined/>
            }
        ]
    },
];
const managerMenu = [
    {
        title: "Главная",
        url: "/",
        icon: <BarChartOutlined/>
    },
    {
        title: "Менеджмент",
        icon: <AppstoreOutlined/>,
        sub: [
            {
                title: "Заказы",
                url: "/orders",
                icon: <ShoppingCartOutlined/>
            },
            {
                title: "Оплаченные",
                url: "/payed-orders",
                icon: <MoneyCollectFilled/>
            },
            {
                title: "Клиенты",
                url: "/clients",
                icon: <UserSwitchOutlined/>
            },
            {
                title: "Устройства",
                url: "/devices",
                icon: <LaptopOutlined/>
            },

        ]
    },
    {
        title: "Прочее",
        icon: <CloudOutlined/>,
        sub: [
            {
                title: "Откуда Узнали",
                url: "/whereKnown",
                icon: <AliwangwangOutlined/>
            },
            {
                title: "Внешний вид устройства",
                url: "/deviceAppearance",
                icon: <AliwangwangOutlined/>
            }
        ]
    },
];


export {adminMenu, managerMenu}


// {
//     title: "Склад",
//         icon: <ShopOutlined/>,
//     sub: [
//     {
//         title: "Склад Запчастей",
//         url: "/stock",
//         icon: <AppstoreOutlined/>
//     },
//     {
//         title: "Склад Запчастей",
//         url: "/stock/defect",
//         icon: <AppstoreOutlined/>
//     },
//     {
//         title: "Категории",
//         url: "/stock/categories",
//         icon: <AppstoreOutlined/>
//     }
//
// ]
// },