import {Card, Divider, Typography} from "antd";
import {Pie} from "@ant-design/charts";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/http.hook";

const {Text} = Typography;
const WhereKnownPieChart = () => {
    const [data, setData] = useState([]);
    const {currentOffice} = useContext(AuthContext);
    const {request, loading} = useHttp();
    const pieConfig = {
        appendPadding: 10,
        data: data,
        angleField: 'value',
        colorField: 'type',
        radius: 0.6,
        legend: {
            layout: 'horizontal',
            position: 'top'
        },
        label: {
            type: 'outer',
            labelHeight: 50,
            content: '{name} {percentage}',
        },
        tooltip: {
            formatter: (data) => {
                return {name: data.type, value: Number(data.value).toFixed(2) + '%'}
            }
        },
        interactions: [{type: 'pie-legend-active'}, {type: 'element-active'}],
        loading
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(currentOffice)]);

    async function fetchData() {
        if (currentOffice && currentOffice.value) {
            const filter = JSON.stringify({
                office: currentOffice.value,
            });
            const whereKnown = await request(`/api/analytics/whereKnownClients?filter=${filter}`, 'GET');
            setData(whereKnown)
        }
    }

    return (
        <Card hoverable style={{margin: "10px 0"}}>
            <Text type={'secondary'} style={{textAlign: 'center'}}>
                Откуда пришёл клиент
            </Text>
            <Divider style={{margin: '10px 0'}}/>
            <Pie {...pieConfig}/>
        </Card>
    )
};

export default WhereKnownPieChart;