import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";

const form = {
    name: 'client',
    title: 'База Клиентов',
    schema: [
        {
            type: 'string',
            name: 'name',
            label: 'Имя',
        },
        {
            type: 'string',
            name: 'phone',
            label: 'Номер Телефона',
        }
    ]
};

const columns = [
    {
        dataIndex: 'name', title: "Имя", sorter: () => {}
    },
    {
        dataIndex: 'phone', title: "Номер Телефона", sorter: () => {}
    }
];


const ClientPage = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            title={form.title}
            form={form}
            tableColumns={columns}
            tableSearchCols={['phone', 'name']}
        />
    )
};

export default ClientPage;