import React from 'react'
import PropTypes from "prop-types";
import logo from "./logo.jpg";
import phoneLogo from "./phone.gif";
import {Divider} from "antd";
import moment from "moment";
import formatValue from "../../utils/formatValue";
import stamp from "./stamp.png";

const PrintGuarantee = (props) => {
    const {
        orderId,
        problem,
        readyDate,
        doneComment,
        manager,
        guarantee,
        price,
        client: {
            name,
            phone,
        },
        device: {
            serial,
            brand,
            model,
            password
        },
        printRef,
        addStamp
    } = props;
    return (
        <div style={{display: 'none'}}>
            <div ref={printRef}>

                <style type="text/css">
                    {`@media print{@page {
                    size: A4 portrait;
                    margin: 20mm;
                    }`}
                </style>
                <Header/>
                <Body
                    client={{name, phone}}
                    device={{serial, brand, model}}
                    readyDate={readyDate}
                    problem={problem}
                    orderId={orderId}
                    doneComment={doneComment}
                    price={price}
                    guarantee={guarantee}
                    addStamp={addStamp}
                />
            </div>
        </div>
    )
};
export default PrintGuarantee;

PrintGuarantee.propTypes = {
    orderId: PropTypes.string,
    problem: PropTypes.string,
    finishedAt: PropTypes.string,
    client: PropTypes.shape({
        name: PropTypes.string,
        phone: PropTypes.string,
    }),
    device: PropTypes.shape({
        serial: PropTypes.string,
        brand: PropTypes.string,
        model: PropTypes.string,
    })
};
PrintGuarantee.defaultProps = {
    client: {},
    device: {}
};


const Header = () => {
    return (
        <>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div>
                    <img src={logo} width={230} alt={'logo'}/>
                </div>
                <div style={{display: 'flex', alignItems: 'flex-start', paddingRight: 15}}>
                    <img src={phoneLogo} height={30} alt={'phone'}/>
                    <div className={'print-reception-phoneNumber'}>+7 (925) 707-70-49</div>
                </div>
            </div>
            <div style={{textAlign: 'right', paddingRight: 15}}>
                Садовая-Сухаревская 2/34с1 офис 301
            </div>
            <hr style={{background: 'black', color: 'black'}}/>
        </>
    )
};

const Body = ({client, device, readyDate, problem, orderId, doneComment, price, guarantee, addStamp}) => {
    return (
        <div>
            <p>
                <b>Акт выполненных работ по договору № CV{orderId}</b>
            </p>
            <table width={'100%'}>
                <tbody>
                <tr>
                    <th>
                        Устройство:
                    </th>
                    <td>
                        {device.brand} {device.model}
                    </td>
                    <th>
                        Дата выдачи:
                    </th>
                    <td>
                        {moment(readyDate).format('DD-MM-YYYY HH:mm:ss')}
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                    <th>
                        Серийный номер:
                    </th>
                    <td>
                        {device.serial}
                    </td>
                    <th>
                        Имя клиента:
                    </th>
                    <td>
                        {client.name}
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>&nbsp;</td>
                </tr>
                <tr>
                    <th></th>
                    <td></td>
                    <th>Телефон:</th>
                    <td>+{client.phone}</td>
                </tr>
                </tbody>
            </table>
            <Divider/>
            <div>
                <p><b>Заявленная неисправность:</b></p>
                <p>{problem}</p>
                <br/>
                <br/>
                <br/>
            </div>
            <div>
                <p><b>Заключение специалиста и выполненные работы:</b></p>
                <p>{doneComment}</p>
                <br/>
                <br/>
                <br/>

            </div>
            <div>
                <h3>Стоимость работ: {formatValue(price, 'currency')}</h3>

                <p><b>Гарантия: {guarantee}</b></p>
            </div>
            <div>
                Гарантия распространяется только на проведенные работы и установленные запчасти. Гарантия не
                распространяется на программное обеспечение. Внимание, ваш аппарат опломбирован. Нарушение гарантийных
                пломб аннулирует нашу гарантию.
                <br/>
                Устройство проверено в моем присутствии, вышеперечисленные услуги выполнены полностью, претензий по
                объему, качеству и срокам оказания услуг не имею.
            </div>
            <br/>
            <br/>
            <div>
                <table width={'100%'}>
                    <tbody>
                    <tr>
                        <th>
                            Заказчик:
                        </th>
                        <td>
                            ___________________
                        </td>
                        <td colSpan={5}> &nbsp; </td>
                        <th style={{position: 'relative'}}>
                            Исполнитель:
                            {addStamp && (
                                <img src={stamp} width={100} style={{position: 'absolute', top: -30, left: 200}}/>
                            )}
                        </th>
                        <td>
                            ___________________
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};
