import React, {useState} from "react";
import {Button, Col, Row, Tooltip} from "antd";
import {UserSwitchOutlined} from "@ant-design/icons";
import {OrdersModal} from "../../ClientOrders";
import DescriptionItem from "./DescriptionItem";

const ClientProfile = ({client, whereKnown}) => {
    const [isOpen, setIsOpen] = useState(false);
    const toggleOpen = (event) => {
        event.stopPropagation();
        setIsOpen(prevState => !prevState)
    };
    return (
        <>
            <b>
                <p className="site-description-item-profile-p">
                    Профайл Клиента
                    <Tooltip title={'Заказы Клиента'}>
                        <Button
                            onClick={toggleOpen}
                            style={{marginLeft: 5}}
                            type={'circled'}
                            icon={<UserSwitchOutlined/>}
                        />
                    </Tooltip>
                </p>
            </b>
            <OrdersModal
                client={client}
                isOpen={isOpen}
                toggleOpen={toggleOpen}
            />
            <Row>
                <Col span={12} xs={24} md={12}>
                    <DescriptionItem title="Имя Клиента" content={client.name}/>
                </Col>
                <Col span={12} xs={24} md={12}>
                    <DescriptionItem title="Номер Телефона"
                                     content={<a href={`tel:${client.phone}`}>{client.phone}</a>}/>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <DescriptionItem title="Откуда Узнал" content={whereKnown.name}/>
                </Col>
            </Row>
        </>
    )
};
export default ClientProfile