import React, {useEffect, useState} from "react";
import moment from "moment";
import {Button, DatePicker} from "antd";

const {RangePicker} = DatePicker;
const dateFormat = 'DD-MM-YYYY';
const locales = {
    "lang": {
        "locale": "ru_RU",
        "placeholder": "Select date",
        "rangePlaceholder": ["От", "До"],
        "today": "Сегодня",
        "now": "Now",
        "backToToday": "Back to today",
        "ok": "Ok",
        "clear": "Clear",
        "month": "Month",
        "year": "Year",
        "timeSelect": "Select time",
        "dateSelect": "Select date",
        "monthSelect": "Choose a month",
        "yearSelect": "Choose a year",
        "decadeSelect": "Choose a decade",
        "yearFormat": "YYYY",
        "dateFormat": "M/D/YYYY",
        "dayFormat": "D",
        "dateTimeFormat": "M/D/YYYY HH:mm:ss",
        "monthFormat": "MMMM",
        "monthBeforeYear": true,
        "previousMonth": "Previous month (PageUp)",
        "nextMonth": "Next month (PageDown)",
        "previousYear": "Last year (Control + left)",
        "nextYear": "Next year (Control + right)",
        "previousDecade": "Last decade",
        "nextDecade": "Next decade",
        "previousCentury": "Last century",
        "nextCentury": "Next century"
    },
    "timePickerLocale": {
        "placeholder": "Select time"
    },
    "dateFormat": "DD-MM-YYYY",
    "dateTimeFormat": "DD-MM-YYYY HH:mm:ss",
    "weekFormat": "YYYY-wo",
    "monthFormat": "YYYY-MM"
};

const RangePickerDate = ({setRange, defaultValue}) => {
    const [dateRangeMoment, setDateRangeMoment] = useState([moment().startOf('month'), moment().endOf('month')]);

    useEffect(() => {
        if(defaultValue === "null") {
            setDateRangeMoment([null, null]);
            return
        }
        setDateRangeMoment([moment().startOf('month'), moment().endOf('month')]);
    }, []);

    function onChange(values) {
        setDateRangeMoment(values);
    }

    function onButtonClick() {
        let $gt = dateRangeMoment && dateRangeMoment[0].format("YYYY-MM-DDTHH:mm:ss.SSS");
        let $lt = dateRangeMoment && dateRangeMoment[1].format("YYYY-MM-DDTHH:mm:ss.SSS");
        setRange({$gt, $lt})
    }
    return(
        <div style={{display: 'flex'}}>
        <RangePicker
            format={dateFormat}
            value={dateRangeMoment}
            onChange={onChange}
            ranges={{
                'Сегодня': [moment().startOf('day'), moment().endOf('day')],
                'Текущий месяц': [moment().startOf('month'), moment().endOf('month')],
            }}
            locale={locales}
        />
            <Button style={{marginLeft: 10}} onClick={onButtonClick}>
                Поиск
            </Button>
        </div>
    )
}

export default RangePickerDate