import React, {useState} from 'react'
import {Tabs} from "antd";
import TransactionHistory from "../components/Finances/TransactionHistory";
import CashBox from "../components/Finances/Cashbox";

const {TabPane} = Tabs;

const Finances = () => {
    const [tab, setTab] = useState("1");
    return (
        <>
            <div className="card-container">
                <Tabs
                    type={"card"}
                    onChange={setTab}
                    activeKey={tab}
                >
                    <TabPane tab={"Касса"} key={"1"}>
                        <CashBox tab={tab}/>
                    </TabPane>
                    <TabPane tab={"История Транзакций"} key={"2"}>
                        <TransactionHistory tab={tab}/>
                    </TabPane>
                </Tabs>
            </div>
        </>
    )
};

export default Finances



