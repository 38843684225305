import React, {useContext, useEffect, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Col, Divider, Row} from "antd";
import {
    ArrowDownOutlined,
    ArrowUpOutlined,
    CreditCardOutlined,
    MoneyCollectOutlined,
} from "@ant-design/icons";
import {AuthContext} from "../../context/AuthContext";
import {useHttp} from "../../hooks/http.hook";
import {CardStatistics} from "../Dashboard/StatisticsCard";
import CashBoxOperations from "./cashBoxOperations";

const CashBox = ({tab}) => {
    const [cashBoxData, setCashBoxData] = useState({sum: 0, cash: 0, card: 0});
    const [openOperation, setOpenOperations] = useState({open: false, type: ''});
    const {currentOffice, userId} = useContext(AuthContext);
    const {request, loading} = useHttp();
    const schema = [
        {
            responsive: {
                xs: 24, sm: 8, md: 8, lg: 8, xl: 8
            },
            cardItem: {
                valueType: 'currency',
                title: 'Наличные',
                icon: <MoneyCollectOutlined/>,
                value: cashBoxData.cash,
                valueStyle: {color: '#3f8600'}
            }
        },
        {
            responsive: {
                xs: 24, sm: 8, md: 8, lg: 8, xl: 8
            },
            cardItem: {
                valueType: 'currency',
                title: 'Карта',
                icon: <CreditCardOutlined/>,
                value: cashBoxData.card,
                valueStyle: {color: '#3f8600'}
            }
        },
        {
            responsive: {
                xs: 24, sm: 8, md: 8, lg: 8, xl: 8
            },
            cardItem: {
                valueType: 'currency',
                title: 'Сумма в кассе',
                icon: <MoneyCollectOutlined/>,
                value: cashBoxData.sum,
                valueStyle: {color: '#3f8600'}
            }
        }
    ];
    useEffect(() => {
        if (tab === '1') {
            fetchData();
        }
    }, [tab]);

    async function fetchData() {
        try {
            if (currentOffice) {
                const filter = JSON.stringify({office: currentOffice.value});
                const data = await request(`/api/finances/getCashCardMoney?filter=${filter}`, 'GET');
                setCashBoxData(data)
            }
        } catch (e) {
            console.log(e)
        }
    }

    async function setCashBoxOperations(values) {
        await request(`/api/finances/cashBoxTransaction`, 'POST', {
            manager: userId,
            office: currentOffice.value,
            amount: values.amount,
            description: values.description,
            type: values.type,
            paymentType: values.paymentType
        });
        setOpenOperations({open: false, type: ''});
        await fetchData()
    }

    return (
        <>
            <div>
                <Button style={{width: 130}}
                        onClick={() => setOpenOperations({open: true, type: 'putInCashBox'})}
                >
                    Пополнение <ArrowUpOutlined/>
                </Button>
                <Button style={{marginLeft: 10, width: 130}}
                        onClick={() => setOpenOperations({open: true, type: 'getFromCashBox'})}
                >
                    Выдача <ArrowDownOutlined/>
                </Button>
            </div>
            <Divider/>
            <Row>
                {schema.map(({cardItem, responsive}, index) => (
                    <Col
                        xs={responsive.xs} sm={responsive.sm}
                        md={responsive.md} lg={responsive.lg}
                        xl={responsive.xl}
                        className={'dashboard__card'}
                    >
                        <CardStatistics
                            key={index}
                            loading={loading}
                            cardItem={cardItem}
                        />
                    </Col>
                ))}
            </Row>
            <CashBoxOperations visible={openOperation.open} type={openOperation.type}
                               loading={loading}
                               cancel={() => setOpenOperations({open: false, type: ''})}
                               submit={setCashBoxOperations}
            />
        </>
    )
};

export default CashBox

CashBox.propTypes = {
    tab: PropTypes.string
}