import React, {useState} from "react";
import {Button, Modal, Tooltip} from "antd";
import {HistoryOutlined} from "@ant-design/icons";
import OrderHistory from "./OrderHistory";

const ShowOrderHistory = React.memo(({record}) => {
    const [open, setOpen] = useState(false);

    const onClick = (e) => {
        e.stopPropagation();
        setOpen(open => !open)
    };
    return (
        <>
            <Tooltip title={'Просмотр истории заказа'}>
                <Button
                    onClick={onClick}
                    style={{marginLeft: 5}}
                    type={'circled'}
                    icon={<HistoryOutlined/>}
                />
            </Tooltip>
            <Modal
                title={
                    <div onClick={(event => event.stopPropagation())}>История заказа № {record.orderNo}</div>
                }
                bodyStyle={{
                    maxHeight: 500,
                    overflowY: 'auto'
                }}
                footer={[]}
                visible={open}
                onCancel={onClick}
                centered
                destroyOnClose
            >
                <OrderHistory
                    orderId={record._id}
                />
            </Modal>
        </>
    )
})

export default ShowOrderHistory