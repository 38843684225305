import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Modal, Skeleton, Row, Col} from 'antd';
import {useHttp} from "../../hooks/http.hook";
import moment from "moment";
import {AuthContext} from "../../context/AuthContext";

const TRANSACTION_TYPE_LOCALES = {
    orderPay: 'Оплата заказа',
    prepayment: 'Предоплата заказа',
    getFromCashBox: 'Выдача наличных',
    putInCashBox: 'Пополнение кассы'
}

const TransactionDetails = ({open, recordId, onClose}) => {
    return (
        <Modal
            visible={open}
            title={'Детали операции'}
            maskClosable={true}
            onCancel={onClose}
            footer={
                <div
                    style={{
                        textAlign: 'right',
                    }}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Закрыть
                    </Button>
                </div>
            }
        >
            {open && (
                <DetailsRender recordId={recordId}/>
            )}
        </Modal>
    )
}

export default TransactionDetails

const DetailsRender = ({recordId}) => {
    const [record, setRecord] = useState({
        manager: {},
        typeOfOperation: {}
    });
    const {request, loading} = useHttp();

    const {currentOffice} = useContext(AuthContext);

    useEffect(() => {
        async function fetchRecord() {
            const response = await request('/api/finances/read', 'POST', {_id: recordId, office: currentOffice.value});
            console.log(response)
            setRecord(response)
        }

        fetchRecord()

    }, [])

    return (
        !loading ? (
            <>
                <Row>
                    <Col span={24}>
                        <DescriptionItem title={'Дата'} content={<b>{moment(record.createdAt).format('DD-MM-YYYY')}</b>}/>
                    </Col>
                    <Col span={24}>
                        <DescriptionItem title={'Сумма'} content={<b>{record.amount} ₽</b>}/>
                    </Col>
                </Row>
                <Row>
                    <Col span={12} sm={24} xs={24}>
                        <DescriptionItem title={'Тип Операции'} content={TRANSACTION_TYPE_LOCALES[record.typeOfOperation.name]}/>
                    </Col>
                    <Col span={12} sm={24} xs={24}>
                        <DescriptionItem title={'Менеджер'} content={record.manager.name}/>
                    </Col>
                </Row>
                <Divider/>
                <Row>
                    <Col>
                        <DescriptionItem title={'Комментарий'} content={record.description}/>
                    </Col>
                </Row>
            </>
        ) : (
            <div style={{marginTop: 24}}>
                <Skeleton active={true}/>
            </div>
        )
    )
}

const DescriptionItem = ({title, content}) => {
    return (
        <div className="order-description-item-wrapper">
            <p className="order-description-item-label">{title}:</p>
            {content}
        </div>
    )
};