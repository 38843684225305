import React, {useEffect, useState} from "react";
import moment from "moment";
import DefaultTable from "../DefaultTable/DefaultTable";
import TransactionDetails from "./TransactionDetails";

const transactionTypeLocales = {
    orderPay: 'Оплата заказа',
    prepayment: 'Предоплата заказа',
    getFromCashBox: 'Выдача наличных',
    putInCashBox: 'Пополнение кассы'
};
const paymentTypeLocales = {
    card: 'безнал',
    cash: 'нал'
};


function renderDetails(value, record) {
    const {typeOfOperation: {paymentType, orderId}} = record;
    return (
        <>
            {transactionTypeLocales[record.typeOfOperation.name]}&nbsp;
            {orderId && (<u style={{cursor: 'pointer'}}>№ {orderId.orderNo}</u>)}&nbsp;
            {paymentTypeLocales[paymentType]}
        </>
    )
}

const TransactionHistory = (props) => {
    const [openDetails, setOpenDetails] = useState({open: false, recordId: null});

    const columns = [
        {
            dataIndex: 'createdAt', title: "Дата",
            sorter: true,
            width: 150,
            render: value => moment(value).format('DD-MM-YYYY')
        },
        {
            dataIndex: 'amount', title: "Сумма", sorter: true,
            render: value => `${value} ₽`
        },
        {
            dataIndex: ['manager', 'name'], title: "Менеджер"
        },
        {
            dataIndex: 'description', title: "Детали",
            render: renderDetails
        },
        {
            dataIndex: 'description', title: "Описание"
        }
    ];
    useEffect(() => {
        if(props.tab === "2") {
           document.getElementById('refreshTableButton').click()
        }

    }, [props.tab]);

    function onOpen(row) {
        setOpenDetails({open: true, recordId: row._id})
    }

    return (
        <div>
            <DefaultTable
                title={''}
                filterByOffice={true}
                predefinedOrder={{column: 'createdAt', direction: 'descend'}}
                hideCreate={true}
                tableColumns={columns}
                schemaId={'finances'}
                onRowClick={onOpen}
                isRangeFilter
            />
            <TransactionDetails
                onClose={() => setOpenDetails({open: false, recordId: null})}
                open={openDetails.open}
                recordId={openDetails.recordId}
            />
        </div>
    )
}

export default TransactionHistory;