import React from "react";
import {Divider, Skeleton} from "antd";
import DetailHeader from "./Header";
import ClientProfile from "./ClientProfile";
import Device from "./Device";
import OrderInformation from "./OrderInformation";

const DetailsRender = ({record, onEditOrder, loading, status}) => {
    const {client = {}, device = {}, manager = {}, executor = {}, whereKnown = {}} = record;
    const {brand = {}, type = {}, model = {}} = device || {};

    return (
        !loading ? (
            <>
                <DetailHeader
                    record={record}
                    color={status.color}
                    label={status.label}
                    onEditOrder={onEditOrder}
                />
                <ClientProfile
                    client={client}
                    whereKnown={whereKnown ? whereKnown : {}}
                />
                <Divider/>
                <Device
                    record={record}
                    type={type}
                    device={device}
                    brand={brand}
                    model={model}
                />
                <Divider/>
                <OrderInformation
                    record={record}
                    manager={manager}
                    executor={executor}
                />
            </>) : (
            <div style={{marginTop: 24}}>
                <Skeleton active={true}/>
                <Skeleton active={true}/>
                <Skeleton active={true}/>
            </div>
        )

    )
};

export default DetailsRender