import React from "react";
import PropTypes from 'prop-types';
import {Menu} from "antd";
import {Link} from "react-router-dom";

const {SubMenu} = Menu;

export const MenuRender = (menu) => {
    return (
        menu.map((item, index) => (
            item.sub ? (
                <React.Fragment key={index}>
                    {MenuItem({title: item.title, icon: item.icon, sub: item.sub})}
                </React.Fragment>
            ) : (
                <React.Fragment key={index}>
                    {MenuLink({title: item.title, icon: item.icon, url: item.url})}
                </React.Fragment>
            )

        ))
    )
};

MenuRender.propTypes = {
    menu: PropTypes.array
};

const MenuItem = ({title, icon, sub}) => {
    return (
        <SubMenu icon={icon} title={title}>
            {MenuRender(sub)}
        </SubMenu>
    )
};

const MenuLink = ({icon, url, title}) => {
    return (
        <Menu.Item icon={icon}>
            <Link to={url}>
                {title}
            </Link>
        </Menu.Item>
    )
};