import React from "react";
import PropTypes from 'prop-types';
import {Card, Divider, Typography} from "antd";
import formatValue from "../../utils/formatValue";
import {Link} from "react-router-dom";
import {LinkOutlined} from "@ant-design/icons";

const {Text} = Typography;

function noop() {
}

const CardStatistics = (props) => {
    const {cardItem, loading} = props;
    const cardMap = Array.isArray(cardItem) ? cardItem : [cardItem];
    return (
        <Card
            hoverable
            loading={loading}
        >
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                {cardMap.map((item, index) => (
                    <Text type={'secondary'} key={index} onClick={item.onClick ? item.onClick : noop}>
                        {item.title}
                        {item.linkUrl && (
                            <span style={{paddingLeft: 10}}>
                        <Link to={item.linkUrl}>
                            <LinkOutlined/>
                        </Link>
                    </span>
                        )}
                    </Text>
                ))}
            </div>
            <Divider style={{margin: 4}}/>
            <div style={{display: 'flex', justifyContent: "space-between"}}>
                {cardMap.map((item, index) => (
                    <div key={index} style={{...item.valueStyle, fontSize: '1.3rem'}} onClick={item.onClick ? item.onClick : noop}>
                        {item.icon} {item.suffix}
                        <span>{formatValue(item.value, item.valueType)}</span>
                    </div>
                ))}
            </div>
        </Card>
    )
};

CardStatistics.propTypes = {
    cardItem: PropTypes.shape({
        title: PropTypes.string,
        value: PropTypes.oneOf([PropTypes.string, PropTypes.number]),
        valueType: PropTypes.oneOf(['currency', "number"]),
        suffix: PropTypes.string,
        valueStyle: PropTypes.object,
        icon: PropTypes.any,
        linkUrl: PropTypes.string
    }),
    loading: PropTypes.bool,
};

export {CardStatistics}