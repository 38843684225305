import React, {useState} from 'react';
import {Card, Checkbox, Form} from "antd";
import {AreaField, SelectField, SelectRemoteFieldCreatable, SwitchField, VoluteField} from "../FormByType";

const OtherBlock = () => {
    const [isPrepayment, setIsPrepayment] = useState(false);
    return (
        <Card title={"Прочее"} size={"small"} style={{marginTop: 5}}>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '40%'}}>
                    <VoluteField
                        name={'price'}
                        label={"Предварительная стоимость"}
                        prefix="₽"
                    />
                </div>
                <div style={{width: '40%'}}>
                    <SelectRemoteFieldCreatable
                        name={'executor'}
                        label={'Мастер'}
                        api={'/executor/query'}
                        createAPI={'/executor/create'}
                        dataKeys={{value: '_id', label: 'name'}}
                        rules={[{required: true}]}
                    />
                </div>
                <div style={{width: '10%'}}>
                    <SwitchField label={'Срочно'} name={'urgently'} rules={[]}/>
                </div>
            </div>
            <div>
                <div style={{display: 'flex', flexDirection: 'row', height: '44px', marginTop: 5}}>
                    <Form.Item
                        shouldUpdate={(prevValues, currentValues) => prevValues.prepayment !== currentValues.prepayment}>
                        {({getFieldValue}) => {
                            // console.log(getFieldValue('orderId'))
                            if (getFieldValue('orderId')) {
                               // console.log(getFieldValue('prepayment'), isPrepayment)
                                if (getFieldValue('prepayment') && !isPrepayment) {
                                    setIsPrepayment(true)
                                }
                                if (!getFieldValue('prepayment')) {
                                   // setIsPrepayment(false)
                                }
                            }

                            return (
                                <Checkbox
                                    checked={isPrepayment}
                                    style={{paddingTop: 5}}
                                    onChange={() => setIsPrepayment(prevState => !prevState)}
                                >
                                    Предоплата
                                </Checkbox>
                            )
                        }}
                    </Form.Item>
                    {
                        isPrepayment && (
                            <div style={{width: 'calc(100% - 120px)', display: 'flex', flexDirection: 'row'}}>
                                <div style={{width: 'calc(100% - 100px)'}}>
                                    <VoluteField
                                        name={'prepayment'}
                                        prefix="₽"
                                    />
                                </div>
                                <div style={{width: '100px'}}>
                                    <SelectField
                                        name={'prepaymentType'}
                                        options={[{value: 'cash', label: 'Нал'}, {value: 'card', label: 'Безнал'}]}
                                    />
                                </div>
                            </div>
                        )
                    }

                </div>
            </div>
            <div style={{width: '100%'}}>
                <AreaField
                    rules={[]}
                    name={'problem'}
                    label={'Описание проблемы'}
                />
            </div>
        </Card>
    )
};

export default OtherBlock;