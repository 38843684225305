import React from "react";
import DefaultTable from "../components/DefaultTable/DefaultTable";

const form = {
    name: 'office',
    title: 'Офисы',
    schema: [
        {
            type: 'string',
            name: 'name',
            label: 'Название',
        },
        {
            type: 'string',
            name: 'address',
            label: 'Адрес',
        }
    ]
};

const columns = [
    {
        dataIndex: 'name', title: "Имя", sorter: () => {}
    },
    {
        dataIndex: 'address', title: "Адресс", sorter: () => {}
    },
];


const OfficesPage = () => {
    return (
        <DefaultTable
            schemaId={form.name}
            title={form.title}
            form={form}
            tableColumns={columns}
        />
    )
};

export default OfficesPage;