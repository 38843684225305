import React from 'react';

const UserProfile  = () => {
    return(
        <div>
            User Profile
        </div>
    )
};

export default UserProfile;