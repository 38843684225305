import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Divider, Modal, Typography} from "antd";

const {Paragraph, Text} = Typography;

const SMSNotification = (props) => {
    const {isVisible, onClose, onSubmit, client, orderNo, loading} = props;
    const [text, setText] = useState(
        `Ваше устройство готово. \nНомер заказа CV ${orderNo}. \nС Уважением apple4you.ru`
    );

    return (
        <Modal
            visible={isVisible}
            title={'Отправить смс-уведомление клиенту?'}
            onOk={() => onSubmit(text)}
            onCancel={onClose}
            centered
            confirmLoading={loading}
            okText={'Отправить'}
            cancelText={'Не отправлять'}
        >
            <div>
                <div style={{marginBottom: 5}}>
                    <Text type={"secondary"}>
                        Клиент:
                    </Text>
                </div>
                <Text>
                    +{client.phone}, {client.name}
                </Text>
            </div>
            <Divider/>
            <div>
                <div style={{marginBottom: 5}}>
                    <Text type={"secondary"}>
                        Текст сообщения:
                    </Text>
                </div>
                <Paragraph
                    editable={{
                        onChange: setText,
                        maxLength: 150,
                        autoSize: {maxRows: 5, minRows: 3},
                    }}
                >
                    {text}
                </Paragraph>
            </div>

        </Modal>
    )
};
export default SMSNotification

SMSNotification.propTypes = {
    isVisible: PropTypes.bool,
    client: PropTypes.object,
    onClose: PropTypes.func,
    onSubmit: PropTypes.func,
    orderNo: PropTypes.number,
    loading: PropTypes.bool
};