import {useCallback, useState} from "react";


export const useSidebar = () => {
    const [open, setOpen] = useState(false)

    const toggle = useCallback(() => {
        setOpen(open => !open)
    }, [])

    return {
        toggle,
        open
    }
}