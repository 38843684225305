import React, {useContext, useEffect, useState} from 'react';
import {Col, Row} from "antd";
import {
    InteractionOutlined,
    MoneyCollectOutlined,
    CheckCircleOutlined,
    BulbOutlined,
    RiseOutlined,
} from "@ant-design/icons";
import OrderPage from "./OrderPage";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";
import RangePickerDate from "../components/RangePicker";
import moment from "moment";
import {CardStatistics} from "../components/Dashboard/StatisticsCard";
import {openNotification} from "../components/Notification";

const defaultFilter = {status: ['NEW', 'inProgress', 'outsource', 'done', 'diagnosed', 'approval', 'waitForPieces', 'needAttention']};

const Dashboard = () => {
    const [dashboardData, setDashboardData] = useState({payed: {}});
    const [predefinedFilter, setPredefinedFilter] = useState(defaultFilter);
    const [statusDone, setStatusDone] = useState(false);
    const schema = [
        {
            responsive: {
                xs: 24, sm: 12, md: 12, lg: 8, xl: 4
            },
            cardItem: {
                valueType: 'number',
                title: 'В работе',
                icon: <InteractionOutlined/>,
                value: dashboardData.inProgress,
                valueStyle: {color: '#3f8600'}
            }
        },
        {
            responsive: {
                xs: 24, sm: 12, md: 12, lg: 8, xl: 4
            },
            cardItem: {
                valueType: 'number',
                title: 'Выданные',
                icon: <CheckCircleOutlined/>,
                value: dashboardData.payed.count,
                linkUrl: "/payed-orders",
                valueStyle: {color: '#3f8600'}
            }
        },
        {
            responsive: {
                xs: 24, sm: 24, md: 12, lg: 8, xl: 6
            },
            cardItem: {
                valueType: 'currency',
                title: 'Касса',
                icon: <MoneyCollectOutlined/>,
                value: dashboardData.cashBox,
                linkUrl: "/finances",
                valueStyle: {color: '#3f8600'}
            }
        },
        {
            responsive: {
                xs: 24, sm: 24, md: 12, lg: 24, xl: 10
            },
            cardItem: [
                {
                    valueType: 'currency',
                    title: 'Прибыль',
                    icon: <RiseOutlined/>,
                    value: dashboardData.payed.income,
                    valueStyle: {color: '#3f8600'}
                },
                {
                    valueType: 'currency',
                    title: 'Ожидание оплаты',
                    icon: <BulbOutlined/>,
                    value: dashboardData.estimatedIncome,
                    valueStyle: {color: statusDone ? '#fa8c16' : '#3f8600'},
                    onClick: () => setDoneFilter()
                }],
        }

    ];
    const [dateRangeStrings, setDateRangeStrings] = useState({
        $gt: moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS"),
        $lt: moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS")
    });
    const {request, loading, error, clearError} = useHttp();
    const {currentOffice} = useContext(AuthContext);

    useEffect(() => {
        if (currentOffice && currentOffice.value) {
            fetchData()
        }
    }, [JSON.stringify(currentOffice), JSON.stringify(dateRangeStrings)]);

    useEffect(() => {
        error && openNotification('error', error);
        clearError();
    }, [error]);

    const fetchData = async () => {
        if (currentOffice && currentOffice.value) {
            const filter = JSON.stringify({office: currentOffice.value, readyDate: dateRangeStrings});
            try {
                const data = await request(`/api/dashboard/dashboardData?filter=${filter}`, 'GET');
                setDashboardData(data)
            } catch (e) {
                console.log(e)
            }
        }
    };

    function setRange(range) {
        setDateRangeStrings(range)
    }

    function setDoneFilter() {
        setStatusDone(prevState => !prevState);
        setPredefinedFilter(statusDone ? defaultFilter : {status: ['done']})
    }

    return (
        <div>
            <div className={'dashboard__range-picker'}>
                <RangePickerDate setRange={setRange}/>
            </div>
            <Row>
                {schema.map(({cardItem, responsive}, index) => (
                    <Col
                        xs={responsive.xs}
                        sm={responsive.sm}
                        md={responsive.md}
                        lg={responsive.lg}
                        xl={responsive.xl}
                        className={'dashboard__card'}
                    >
                        <CardStatistics
                            key={index}
                            loading={loading}
                            cardItem={cardItem}
                        />
                    </Col>
                ))}
            </Row>
            <Row>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}
                     className={'dashboard__card'}
                >
                    <OrderPage
                        onCreateCallback={fetchData}
                        hideCreate={true}
                        predefinedFilter={predefinedFilter}
                    />
                </Col>
            </Row>
        </div>
    )
};

export default Dashboard