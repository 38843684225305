import React, {useContext} from "react";
import {Avatar, Dropdown} from "antd";
import {UserOutlined} from "@ant-design/icons";
import UserMenu from "./UserMenu";
import CreateOrderFromHeader from "./CreateOrderFromHeader";
import {AuthContext} from "../../../context/AuthContext";

const HeaderMenu = () => {
    const {userName} = useContext(AuthContext);
    return (
        <>
            <div style={{margin: "0 30px"}}>
                <CreateOrderFromHeader/>
            </div>
            <Dropdown overlay={() => <UserMenu/>}>
                <div style={{height: '100%'}}>
                    <Avatar className="ant-dropdown-link" style={{backgroundColor: '#87d068', marginBottom: 3}}
                            icon={<UserOutlined/>} onClick={e => e.preventDefault()}>
                        {userName}
                    </Avatar>

                </div>

            </Dropdown>
        </>
    )
};
export default HeaderMenu