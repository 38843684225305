import React, {useContext, useState} from "react";
import {Menu} from "antd";
import ChangePassword from "../../ChangePassword";
import {AuthContext} from "../../../context/AuthContext";

const UserMenu = () => {
    const auth = useContext(AuthContext);
    const [isOpenChangePassword, setIsOpenChangePassword] = useState(false)
    return (
        <>
            <Menu onClick={(e) => e.key === 'logout' ? auth.logout() : null}>
                <Menu.Item onClick={() => setIsOpenChangePassword(true)}>Сменить пароль</Menu.Item>
                <Menu.Item key={'logout'}>Выход</Menu.Item>
            </Menu>
            <ChangePassword
                open={isOpenChangePassword}
                onClose={() => setIsOpenChangePassword(false)}
            />
        </>
    )
};
export default UserMenu