import React, {useEffect, useRef, useState} from "react";
import {Button, Drawer, Popconfirm} from 'antd'
import _ from "lodash";
import {useHttp} from "../../../hooks/http.hook";
import OrderForm from "../../OrderForm/OrderForm";
import {statuses} from "../../../utils/locales";
import DetailsRender from "./DetailsRender";

const OrderDetails = ({open, record: {_id}, onClose}) => {
    const formRef = useRef(null);
    const {request, loading} = useHttp();
    const [isVisibleOrderEdit, setIsVisibleOrderEdit] = useState(false);
    const [record, setRecord] = useState({});
    const [status, setStatus] = useState({color: "", label: ""});
    const [popConfirm, setPopConfirm] = useState(false);

    useEffect(() => {
        _id && fetchData()
    }, [_id]);

    async function fetchData() {
        try {
            const order = await request('/api/order/read', 'POST', {id: _id});
            const labelIndex = _.findIndex(statuses, (o) => {
                return order.status === o.value
            });
            setRecord(order);
            setStatus(statuses[labelIndex])
        } catch (e) {
            console.log(e)
        }
    }
    async function deleteOrder(id) {
        try {
            await request('/api/order/delete', 'POST', {id});
            setPopConfirm(false);
            setIsVisibleOrderEdit(false);
            onClose();
            if (document.getElementById('refreshTableButton')) {
                document.getElementById('refreshTableButton').click();
            }
        } catch (e) {
            console.log(e)
        }
    }

    function onEditOrder() {
        setIsVisibleOrderEdit(true)
    }
    function onCloseEditOrder() {
        setIsVisibleOrderEdit(false);
        formRef.current && formRef.current.resetFields();
    }

    return (
        <Drawer
            visible={open}
            width={700}
            onClose={onClose}
            footer={
                <div
                    style={{textAlign: 'right'}}
                >
                    <Button onClick={onClose} style={{marginRight: 8}}>
                        Закрыть
                    </Button>
                </div>
            }
        >
            <DetailsRender
                record={record}
                status={status}
                onEditOrder={onEditOrder}
                loading={loading}
            />
            <Drawer
                visible={isVisibleOrderEdit}
                width={800}
                onClose={onCloseEditOrder}
                footer={
                    <div
                        style={{textAlign: 'right'}}
                    >
                        <Button onClick={onCloseEditOrder} style={{marginRight: 8}}>
                            Отмена
                        </Button>
                        <Popconfirm
                            title={'Вы уверены ?'}
                            okText={'Удалить'}
                            cancelText={'Отмена'}
                            visible={popConfirm}
                            onConfirm={() => deleteOrder(_id)}
                            onCancel={() => setPopConfirm(false)}
                            okButtonProps={{loading}}
                        >
                            <Button onClick={() => setPopConfirm(true)} style={{marginRight: 8}}>
                                Удалить
                            </Button>
                        </Popconfirm>

                        <Button type="primary" htmlType="submit" form={'orderUpdateForm'}>
                            Обновить
                        </Button>
                    </div>
                }
            >
                <OrderForm
                    formRef={formRef}
                    formId={'orderUpdateForm'}
                    onClose={onCloseEditOrder}
                    visible={isVisibleOrderEdit}
                    onCreateCallback={fetchData}
                    recordId={_id}
                />
            </Drawer>
        </Drawer>
    )
};

export default OrderDetails