import React, {useContext, useState} from "react";
import PropTypes from 'prop-types';
import {Button, Modal, Tag} from "antd";
import _ from "lodash";
import ChangeStatusForm from "./ChangeStatusForm";
import {useHttp} from "../../hooks/http.hook";
import PrintOrder from "./PrintOrder";
import ShowOrderHistory from "./ShowOrderHistory";
import {AuthContext} from "../../context/AuthContext";
import {statuses} from "../../utils/locales";
import SMSNotification from "./SMSNotification";


const ChangeStatus = React.memo(({value, record, fetchData}) => {
    const {request, loading} = useHttp();
    const [visible, setVisible] = useState(false);
    const [smsNotification, setSmsNotification] = useState(false);
    const {currentOffice} = useContext(AuthContext);
    const onFinish = async (values) => {
        const orderId = record._id;
        if (currentOffice) {
            await request('/api/order/changeStatus', 'POST', {orderId, office: currentOffice.value, ...values});
            if (values.status === 'done') {
                setSmsNotification(true);
                return
            }
            setVisible(false);
            await fetchData()
        }
    };
    const onSubmitSms = async (message) => {
        if(currentOffice) {
            await request('/api/sms-service/sendNotification', 'POST', {
                clientId: record.client._id,
                office: currentOffice.value,
                message
            });
            onCloseSms();
        }
    };
    const onCloseSms = () => {
        setVisible(false);
        setSmsNotification(false);
        fetchData()
    };
    const labelIndex = _.findIndex(statuses, (o) => {
        return value === o.value
    });
    return (
        <div onClick={event => event.stopPropagation()}>
            <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                <Tag
                    color={statuses[labelIndex] ? statuses[labelIndex].color : null}
                    onClick={(e) => {
                        e.stopPropagation();
                        setVisible(true)
                    }}
                    style={{cursor: 'pointer'}}
                >
                    {statuses[labelIndex] ? statuses[labelIndex].label : 'Без статуса'}
                </Tag>
                <div style={{display: 'flex'}}>
                    <PrintOrder record={record}/>
                    <ShowOrderHistory record={record}/>
                </div>
            </div>
            <Modal
                title={
                    <div onClick={(event => event.stopPropagation())}>
                        <span>Смена статуса заказа № {record.orderNo}</span>
                        <ShowOrderHistory record={record}/>
                    </div>
                }
                visible={visible}
                onCancel={() => setVisible(false)}
                centered
                destroyOnClose
                footer={[
                    <Button key="close" onClick={() => setVisible(false)} disabled={loading}>
                        Отмена
                    </Button>,
                    <Button key="submit" type="primary" htmlType={"submit"} form={"statusForm"} disabled={loading}>
                        Сохранить
                    </Button>,
                ]}
            >
                <ChangeStatusForm
                    value={value}
                    record={record}
                    onFinish={onFinish}
                />
            </Modal>
            <SMSNotification
                isVisible={smsNotification}
                client={record.client}
                orderNo={record.orderNo}
                onClose={onCloseSms}
                onSubmit={onSubmitSms}
                loading={loading}
            />
        </div>
    )
});

export default ChangeStatus;

ChangeStatus.propTypes = {
    value: PropTypes.string,
    record: PropTypes.object,
    fetchData: PropTypes.func
};