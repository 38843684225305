import React, {useContext} from 'react';
import {Form, Modal, Input, Button} from "antd";
import {useHttp} from "../hooks/http.hook";
import {AuthContext} from "../context/AuthContext";

const ChangePassword = ({open, onClose}) => {
    const {userId} = useContext(AuthContext);
    const {request, loading} = useHttp();

    async function onChangePassword(values) {
        console.log(values)
            await request(
                '/api/user/changePassword',
                'POST',
                {_id: userId, currentPassword: values.currentPassword, newPassword: values.password},
            )
        onClose();
    }

    return(
        <Modal
            visible={open}
            title={'Смена пароля'}
            footer={[
                <Button key="close" onClick={onClose}>
                    Отмена
                </Button>,
                <Button loading={loading} key="submit" type="primary" htmlType={"submit"} form={"changePasswordForm"}>
                    Сменить Пароль
                </Button>,
            ]}
        >
            <Form onFinish={onChangePassword} id={'changePasswordForm'}>
                <Form.Item
                    name="currentPassword"
                    label="Текущий пароль"
                    rules={[
                        {
                            required: true,
                            message: 'Введите текущий пароль',
                        }
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="password"
                    label="Новый пароль"
                    rules={[
                        {
                            required: true,
                            message: 'Введите новый пароль',
                        },
                    ]}
                    hasFeedback
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item
                    name="confirm"
                    label="Подтверждение пароля"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: 'Подтвердите пароль',
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('Пароли которые вы ввели не совпадают'));
                            },
                        }),
                    ]}
                >
                    <Input.Password />
                </Form.Item>
            </Form>
        </Modal>
    )
}

export default ChangePassword;