import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom'
import AuthPage from './pages/AuthPage'
import UserPage from "./pages/UserPage";
import OfficesPage from "./pages/OfficePage";
import UserProfile from "./pages/UserProfile";
import StockPage from "./pages/StockPage";
import StockCategories from "./pages/StockCategories";
import StockDefectPage from "./pages/StockDefect";
import ExecutorPage from "./pages/ExecutorPage";
import ClientPage from "./pages/ClientPage";
import WhereKnown from "./pages/WhereKnown";
import DevicePage from "./pages/DevicePage";
import OrderPage from "./pages/OrderPage";
import DeviceAppearance from "./pages/DeviceAppearance";
import PayedOrders from "./pages/PayedOrders";
import Dashboard from "./pages/Dashboard";
import Finances from "./pages/Finances";
import Outsource from "./pages/Outsource";
import PrintReception from "./components/Print/PrintReception";
import AnalyticsPage from "./pages/AnalyticsPage/AnalyticsPage";
import MainSms from "./pages/system/MainSms/MainSms";
import CreateMailing from "./pages/system/MainSms/CreateMailing";

export const useRoutes = isAuthenticated => {
    if (isAuthenticated) {
        return (
            <Switch>
                <Route path="/" exact>
                    <Dashboard/>
                </Route>
                <Route path="/printTest" exact>
                    <PrintReception/>
                </Route>
                <Route path="/profile" exact>
                    <UserProfile/>
                </Route>
                <Route path="/users" exact>
                    <UserPage/>
                </Route>
                <Route path="/executors" exact>
                    <ExecutorPage/>
                </Route>
                <Route path="/offices" exact>
                    <OfficesPage/>
                </Route>
                <Route path="/stock" exact>
                    <StockPage/>
                </Route>
                <Route path="/stock/defect" exact>
                    <StockDefectPage/>
                </Route>
                <Route path="/stock/categories" exact>
                    <StockCategories/>
                </Route>

                <Route path="/clients" exact>
                    <ClientPage/>
                </Route>

                <Route path="/whereKnown" exact>
                    <WhereKnown/>
                </Route>
                <Route path="/outsource" exact>
                    <Outsource/>
                </Route>

                <Route path="/deviceAppearance" exact>
                    <DeviceAppearance/>
                </Route>

                <Route path="/devices" exact>
                    <DevicePage/>
                </Route>
                <Route path="/finances" exact>
                    <Finances/>
                </Route>

                <Route path="/payed-orders">
                    <PayedOrders />
                </Route>
                <Route path="/analytics">
                    <AnalyticsPage />
                </Route>

                <Route path="/orders">
                    <OrderPage predefinedFilter={{status: ['inProgress', 'done', 'NEW', 'diagnosed', 'outsource']}}/>
                </Route>

                <Route exact path="/system/main-sms">
                    <MainSms/>
                </Route>
                <Route path="/system/main-sms/create-mailing">
                    <CreateMailing/>
                </Route>
            </Switch>
        )
    }

    return (
        <Switch>
            <Route path="/" exact>
                <AuthPage/>
            </Route>
            <Redirect to="/"/>
        </Switch>
    )
};
