import React, {useState} from "react";
import PropTypes from 'prop-types';
import {Button, Typography} from "antd";
import EnterKeyModal from "./EnterKeyModal";
import {useHttp} from "../../../../hooks/http.hook";

const {Text} = Typography;

const Setup = ({setIsConnected}) => {
    const [isOpen, setIsOpen] = useState(false);
    const {request, loading} = useHttp();

    async function confirmApiKey(credentials) {
        try {
            await request('/api/sms-service/sign-in', 'POST', credentials)
            setIsConnected(true)
            setIsOpen(false);
        } catch (e) {
            setIsConnected(false)
            console.log(e)
        }
    }

    return (
        <div className={"main-sms-container-not-connected"}>
            <Text
                className={"main-sms-not-connected-title"}
                type={"secondary"}
            >
                MainSMS не подключен, пожалуйста введите API ключ из <br/>
                <a href={"https://mainsms.ru/office/api_accounts"} target={"_blank"}>личного кабинета</a>
            </Text>
            <br/>
            <Button type={"primary"} onClick={() => setIsOpen(true)}>
                Добавить API ключ
            </Button>

            <EnterKeyModal
                isOpen={isOpen}
                confirmApiKey={confirmApiKey}
                loading={loading}
                onClose={() => setIsOpen(false)}
            />
        </div>
    )
};

export default Setup

Setup.propTypes = {
    confirmApiKey: PropTypes.func,
    loading: PropTypes.bool
};
