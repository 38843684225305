import React, {useState} from 'react';
import {Divider} from "antd";
import moment from 'moment';
import OrderPage from "./OrderPage";
import RangePickerDate from "../components/RangePicker";
import ClientOrders from "../components/OrderPage/ClientOrders";
import ChangeStatus from "../components/OrderPage/ChangeStatus";
import Paragraph from "antd/lib/typography/Paragraph";


const payedOrdersColumns = (fetchData) => {
    return [
        {
            dataIndex: 'orderNo', title: "№", sorter: () => {
            },
            width: 50
        },
        {
            dataIndex: 'readyDate', title: "Дата", sorter: true, defaultSortOrder: 'descend',
            render: value => <span style={{whiteSpace: "nowrap"}}> {moment(value).format('DD-MM-YYYY')}</span>,
            width: 120
        },
        {
            dataIndex: 'client', title: "Клиент",
            shouldCellUpdate:  (record, prevRecord) => JSON.stringify(record.client) !== JSON.stringify(prevRecord.client),
            render: value => <ClientOrders client={value || {}}/>,
        },
        {
            dataIndex: ['device'],
            shouldCellUpdate:  (record, prevRecord) => record.device !== prevRecord.device,
            title: "Устройство",
            render: value => value ?
                <span style={{whiteSpace: "nowrap"}}> {value.type.name}, {value.brand.name} {value.model.name}
                    <Divider style={{margin: 1}}/>
                    <Paragraph copyable onClick={e => e.stopPropagation()}>{value.serial}</Paragraph></span> : <span>Такого устройства нет</span>,
        },
        {
            dataIndex: "problem",
            title: "Неисправность"
        },
        {
            dataIndex: ['price', 'expenses', 'paymentType'],
            align: 'right',
            title: <span>Цена <Divider style={{margin: 2}}/> Расход</span>,
            render: (value, record) =>
                <span>
                    <span style={{color: "#00701a", whiteSpace: "nowrap"}}>{record.price !== null && record.price !== undefined ? `${record.price} ₽` : '-'}</span>
                    <Divider style={{margin: 2}}/>
                    <span style={{color: "#001970", whiteSpace: "nowrap"}}>{record.expenses !== null && record.expenses !== undefined ? `${record.expenses} ₽` : '-'}</span>
                    </span>
        },
        {
            dataIndex: ['price', 'expenses'],
            title: "Доход",
            align: 'right',
            sorter: () => {
            },
            render: (value, record) => <span style={{whiteSpace: "nowrap"}}>{(record.price || 0) - (record.expenses || 0)} ₽ </span>,
        },
        {
            dataIndex: 'status',
            title: "Статус",
            shouldCellUpdate: (record, prevRecord) => record.status !== prevRecord.status,
            render: (value, record) => (<ChangeStatus value={value} record={record} fetchData={fetchData}/>),
            filters: [
                {text: 'Новые', value: 'NEW'},
                {text: 'Продиагностирован', value: 'diagnosed'},
                {text: 'В работе', value: 'inProgress'},
                {text: 'Аутсорс', value: 'outsource'},
                {text: 'Готовые', value: 'done'},
                {text: 'Оплаченные', value: 'payed'},
            ],
        },
    ];
};

const predefinedSort = {column: 'readyDate', direction: 'descend'}

const PayedOrders = () => {
    const [dateRangeStrings, setDateRangeStrings] = useState({
        $gt: moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS"),
        $lt: moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS")
    });

    return (
        <>
            <RangePickerDate setRange={setDateRangeStrings}/>
            <Divider/>
            <OrderPage
                predefinedFilter={{status: ['payed'], readyDate: dateRangeStrings}}
                predefinedSort={predefinedSort}
                customColumns={payedOrdersColumns}
            />
        </>
    )
};

export default PayedOrders