import React, {useState} from 'react';
import {AutoComplete, Card, Checkbox, Divider, Form, Input, Select} from "antd";
import {DefaultField} from "../FormByType";
import {useHttp} from "../../hooks/http.hook";
import {PlusOutlined} from "@ant-design/icons";
import _ from 'lodash'

const {Option} = Select;

const DeviceBlock = ({onSelectDevice, currentType, disabledBrand, currentBrand, disabledModel, onSelectType, onSelectBrand, disabledDevice}) => {
    return (
        <Card title={"Устройство"} size="small" style={{marginTop: 5}}>
            <SelectRemoteFieldDevice
                rules={[{required: !disabledDevice}]}
                disabled={disabledDevice}
                name={'device'}
                label={<>Серийный номер ( не могу ввести &nbsp; <SerialCheckBox/> &nbsp;)</>}
                isArray={false}
                dataKeys={{value: '_id', label: 'serial'}}
                api={'/device/query'}
                createAPI={'/device/create'}
                onSelectDevice={onSelectDevice}
            />
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '30%'}}>
                    <TypeField
                        rules={[{required: true}]}
                        name={'deviceType'}
                        label={'Тип'}
                        isArray={false}
                        dataKeys={{value: '_id', label: 'name'}}
                        api={'/deviceType/query'}
                        createAPI={'/deviceType/create'}
                        onSelect={onSelectType}
                    />
                </div>
                <div style={{width: '30%'}}>
                    <BrandField
                        rules={[{required: true}]}
                        name={'deviceBrand'}
                        label={'Бренд'}
                        isArray={false}
                        dataKeys={{value: '_id', label: 'name'}}
                        api={'/deviceBrand/query'}
                        createAPI={'/deviceBrand/create'}
                        typeId={currentType}
                        disabled={disabledBrand}
                        onSelect={onSelectBrand}
                    />
                </div>
                <div style={{width: '30%'}}>
                    <ModelField
                        rules={[{required: true}]}
                        name={'deviceModel'}
                        label={'Модель'}
                        isArray={false}
                        dataKeys={{value: '_id', label: 'name'}}
                        api={'/deviceModel/query'}
                        createAPI={'/deviceModel/create'}
                        typeId={currentType}
                        brandId={currentBrand}
                        disabled={disabledModel}
                    />
                </div>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{width: '20%'}}>
                    <DefaultField rules={[{required: true}]} label={'Пин'} name={'devicePin'}/>
                </div>
                <div style={{width: '75%'}}>
                    <AppearanceField
                        api={'/deviceAppearance/query'}
                        name={'deviceAppearance'}
                        label={'Внешний вид'}
                        isArray={false}
                        dataKeys={{value: '_id', label: 'name'}}
                    />
                </div>
            </div>
        </Card>
    )
};

export default DeviceBlock;

const SerialCheckBox = () => {
    return(
        <Form.Item   name={'withoutSerial'} className={'form-item-serial-checkbox'} valuePropName="checked" >
            <Checkbox />
        </Form.Item>
    )
}
const SelectRemoteFieldDevice = ({name, label, rules, api, dataKeys, disabled, onSelectDevice}) => {
    const [data, setData] = useState([]);
    const {request, loading} = useHttp();

    async function onSearch(text) {
        const {label} = dataKeys;
        let filter = {serial: {"$regex": text, "$options": "i"}};
        const params = `?page=1&pageSize=25&filter=${JSON.stringify(filter)}`;
        if (text) {
            const data = await request(`/api${api}${params}`, 'GET');
            let collectedData = [];
            if (data.content) {
                for (let item of data.content) {
                    collectedData.push({
                        value: item[label],
                        label: `${item[label]} - ${item.type.name}`,
                        obj: item
                    })
                }
            }
            setData(collectedData);
            return
        }
        setData([])
    }

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <AutoComplete
                disabled={disabled}
                showSearch
                onSelect={(v, i) => onSelectDevice(i.obj)}
                backfill
                filterOption={false}
                onSearch={_.debounce(onSearch, 600)}
                loading={loading}
                options={data}
            >
                <Input />
            </AutoComplete>
        </Form.Item>
    )
};
const TypeField = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled, onSelect}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen) {
            await fetchData()
        }
    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                onSelect={onSelect}
                showSearch
                filterOption
                options={data}
                optionFilterProp={"label"}
                labelInValue
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};
const BrandField = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled, typeId, onSelect}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen) {
            await fetchData()
        }
    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem, type: typeId});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999&filter=${JSON.stringify({type: typeId})}`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                showSearch
                filterOption
                options={data}
                optionFilterProp={"label"}
                onSelect={onSelect}
                labelInValue
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};
const ModelField = ({name, label, rules, api, dataKeys, isArray, createAPI, disabled, typeId, brandId}) => {
    const [data, setData] = useState([]);
    const [newItem, setNewItem] = useState('');
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen) {
            await fetchData()
        }

    };
    const onChangeNewItem = ({target: {value}}) => {
        setNewItem(value)
    };
    const addNewItem = async () => {
        await request(`/api${createAPI}`, 'POST', {name: newItem, type: typeId, brand: brandId});
        setNewItem('');
        await fetchData();
    };
    const fetchData = async () => {
        const {value, label} = dataKeys;
        const params = `?page=1&pageSize=9999&filter=${JSON.stringify({type: typeId, brand: brandId})}`;
        const data = await request(`/api${api}${params}`, 'GET');
        let collectedData = [];
        if (data.content) {
            for (let item of data.content) {
                collectedData.push({value: item[value], label: item[label]})
            }
        }
        setData(collectedData);
    };

    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                showSearch
                filterOption
                options={data}
                optionFilterProp={"label"}
                labelInValue
                disabled={disabled}
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={isArray && "multiple"}
                dropdownRender={menu => (
                    <div>
                        {menu}
                        <Divider style={{margin: '4px 0'}}/>
                        <div style={{display: 'flex', flexWrap: 'nowrap', padding: 8}}>
                            <Input
                                style={{flex: 'auto'}}
                                value={newItem}
                                onChange={onChangeNewItem}
                            />
                            <a
                                style={{flex: 'none', padding: '8px', display: 'block', cursor: 'pointer'}}
                                onClick={addNewItem}
                            >
                                <PlusOutlined/> Добавить
                            </a>
                        </div>
                    </div>
                )}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
};
const AppearanceField = ({name, label, rules, api, dataKeys}) => {
    const [data, setData] = useState([]);
    const {request, loading} = useHttp();
    const onOpen = async (isOpen) => {
        if (isOpen && data.length === 0) {
            const {label} = dataKeys;
            const params = `?page=1&pageSize=9999`;
            const data = await request(`/api${api}${params}`, 'GET');
            let collectedData = [];
            if (data.content) {
                for (let item of data.content) {
                    collectedData.push({
                        value: item[label],
                        label: `${item.name}`,
                        obj: item
                    })
                }
            }
            setData(collectedData);
        }
    };
    return (
        <Form.Item
            name={name}
            label={label}
            rules={rules}
        >
            <Select
                showSearch
                filterOption
                options={data}
                optionFilterProp={"label"}
                labelInValue
                onDropdownVisibleChange={onOpen}
                loading={loading}
                mode={'tags'}
            >
                {data.map(option => {
                    return (
                        <Option key={option.value} value={option.value}>
                            {option.label}
                        </Option>
                    )
                })}
            </Select>
        </Form.Item>
    )
}