import React from "react";
import PropTypes from 'prop-types';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import {Modal} from "antd";
const {confirm} = Modal;

function showConfirm(submit, type) {
    confirm({
        title: 'Добавить печать в документ?',
        icon: <ExclamationCircleOutlined />,
        onOk() {
            return new Promise(async (resolve, reject) => {
                try {
                    await submit(true, type);
                    resolve();
                } catch (e) {
                    reject();
                }
            }).catch(() => {
                console.log('Oops errors!')
            });
        },
        onCancel() {
            return new Promise(async (resolve, reject) => {
                try {
                    await submit(false, type);
                    resolve();
                } catch (e) {
                    reject();
                }
            }).catch(() => {
                console.log('Oops errors!')
            });
        },
        centered: true,
        okText: 'Да',
        cancelText: 'Нет'
    });
}
export {showConfirm};