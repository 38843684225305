import React, {useContext, useEffect, useState} from 'react';
import moment from "moment";
import {Col, Row} from "antd";
import {
    InteractionOutlined,
    RiseOutlined,
} from "@ant-design/icons";
import {useHttp} from "../../hooks/http.hook";
import {AuthContext} from "../../context/AuthContext";
import RangePickerDate from "../../components/RangePicker";
import {CardStatistics} from "../../components/Dashboard/StatisticsCard";
import WhereKnownPieChart from "./WhereKnownPieChart";
import IncomeByMonthsChart from "./IncomeByMonthsChart";
import AverageCards from "./AverageCards";

const AnalyticsPage = () => {
    const [dateRangeStrings, setDateRangeStrings] = useState({
        $gt: moment().startOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS"),
        $lt: moment().endOf('month').format("YYYY-MM-DDTHH:mm:ss.SSS")
    });

    function setRange(range) {
        setDateRangeStrings(range)
    }

    return (
        <div className={'dashboard__range-picker'}>
            <RangePickerDate setRange={setRange}/>
            <AverageCards dateRange={dateRangeStrings}/>
            <Row>
                <Col className={'dashboard__card'} xs={24} sm={24} md={12} lg={16} xl={16} style={{minHeight: 300}}>
                    <IncomeByMonthsChart/>
                </Col>
                <Col className={'dashboard__card'} xs={24} sm={24} md={12} lg={8} xl={8} style={{minHeight: 300}}>
                    <WhereKnownPieChart/>
                </Col>
            </Row>
        </div>
    )
};

export default AnalyticsPage