import React from "react";
import {Col, Divider, Row} from "antd";
import moment from "moment";
import CheckOutlined from "@ant-design/icons/lib/icons/CheckOutlined";
import CloseOutlined from "@ant-design/icons/lib/icons/CloseOutlined";
import DescriptionItem from "./DescriptionItem";

const OrderInformation = ({record, manager, executor}) => {
    const forPayment = countPayment(record);
    const isPayed = record.status === 'payed';
    return (
        <>
            <b>
                <p className="site-description-item-profile-p">Информация</p>
            </b>
            <Row>
                <Col span={6} xs={24} md={6}>
                    <DescriptionItem title="Менеджер" content={manager.username}/>
                </Col>
                <Col span={6} xs={24} md={6}>
                    <DescriptionItem title="Мастер" content={executor ? executor.name : "Отсутствует"}/>
                </Col>
                <Col span={12} xs={24} md={12}>
                    <DescriptionItem
                        title="Заказ принят"
                        content={moment(record.createdAt).format('DD-MM-YYYY')}/>
                </Col>
            </Row>
            <Row>
                <Col span={6} xs={12} md={6}>
                    <DescriptionItem
                        title="Предоплата"
                        content={record.prepayment ? `${record.prepayment} ₽` : '0 ₽'}/>
                </Col>
                <Col span={6} xs={12} md={6}>
                    <DescriptionItem
                        title="Цена Ремонта"
                        content={record.price ? `${record.price} ₽` : '0 ₽'}/>
                </Col>
            </Row>
            <Divider style={{margin: 5}}/>
            <Row>
                <Col span={24}>
                    <DescriptionItem
                        title="К оплате"
                        content={forPayment}/>
                </Col>
            </Row>
            <Row>
                <Col span={12} xs={24} md={isPayed ? 12 : 24}>
                    <DescriptionItem
                        title="Статус оплаты"
                        content={isPayed ?
                            <>Оплачен <CheckOutlined style={{lineHeight: 1.7, color: 'green', marginLeft: 5}}/></> :
                            <>Не Оплачен <CloseOutlined style={{lineHeight: 1.7, color: 'red', marginLeft: 5}}/></>}/>
                </Col>
                {isPayed && (
                    <Col span={12}>
                        <DescriptionItem
                            title="Тип оплаты"
                            content={record.paymentType === 'cash' ? 'Нал' : 'Безнал'}
                        />
                    </Col>
                )}
            </Row>
        </>
    )
};
export default OrderInformation

const countPayment = (record) => {
    let pay = 'Цена не указана';
    let prepayment = record.prepayment ? Number(record.prepayment) : 0;
    let price = Number(record.price);
    if (price) {
        pay = `${price - prepayment} ₽`
    }
    return pay
};