import React, {useRef, useState} from "react";
import {Button} from "antd";
import DefaultForm from "../../DefaultForm/DefaultForm";
import orderForm from "../../OrderForm/OrderForm";

const CreateOrderFromHeader = () => {
    const form = {
        name: 'order',
        title: 'Заказы',
        schema: [
            {
                type: 'string',
                name: 'serial',
                label: 'Серийный Номер',
            },
            {
                type: 'selectRemoteCreatable',
                name: 'type',
                label: 'Тип Устройства',
                api: '/deviceType/query',
                createAPI: '/deviceType/create',
                isArray: false,
                dataKeys: {value: '_id', label: 'name'}
            },
            {
                type: 'selectRemoteCreatable',
                name: 'brand',
                label: 'Брэнд Устройства',
                api: '/deviceBrand/query',
                createAPI: '/deviceBrand/create',
                isArray: false,
                dataKeys: {value: '_id', label: 'name'}
            },
            {
                type: 'selectRemoteCreatable',
                name: 'model',
                label: 'Модель Устройства',
                api: '/deviceModel/query',
                createAPI: '/deviceModel/create',
                isArray: false,
                dataKeys: {value: '_id', label: 'name'}
            },
        ],
        enrichment: {
            type: {
                label: "name",
                value: "_id"
            },
            brand: {
                label: "name",
                value: "_id"
            },
            model: {
                label: "name",
                value: "_id"
            }
        }
    };
    const formRef = useRef();
    const [visibleDrawer, setVisibleDrawer] = useState(false);
    const setVisible = () => {
        setVisibleDrawer(prevState => !prevState);
    };
    return (
        <>
            <Button onClick={setVisible}>
                Новый заказ
            </Button>
            <DefaultForm
                drawerWidth={800}
                formId={form.name}
                formRef={formRef}
                visible={visibleDrawer}
                onClose={setVisible}
                form={form}
                isUpdate={false}
                onDelete={() => {
                }}
                formRenderer={orderForm}
                formWrapper={'Drawer'}
            />
        </>
    )
};
export default CreateOrderFromHeader