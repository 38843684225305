import React from "react";

const DescriptionItem = ({title, content}) => {
    return (
        <div className="order-description-item-wrapper">
            <p className="order-description-item-label">{title}:</p>
            {content}
        </div>
    )
};

export default DescriptionItem