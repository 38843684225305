import React from "react";
import {Form} from "antd";
import FormByType from "../FormByType";

const formItemLayout = {
    labelCol: {
        span: 0,
    },
    wrapperCol: {
        span: 0,
    }
};
const layout = {
    wrapperCol: {span: 0, offset: 0},
};

const FormRender = (props) => {
    const {
        form, formRef, formValues, formId,
        onFinish, onClose, fetchData, validateMessages,
        formRenderer, visible, onCreateCallback,
    } = props;
    return (
        form.schema.length !== 0 ? (
            formRenderer ? (
                formRenderer({
                    formRef,
                    form,
                    formValues,
                    onFinish,
                    validateMessages,
                    onClose,
                    fetchData,
                    visible,
                    onCreateCallback,
                    formId
                })
            ) : (
                <Form {...layout}
                      {...formItemLayout}
                      ref={formRef}
                      id={formId || 'form'}
                      layout={'vertical'}
                      fields={formValues}
                      name={form.name}
                      onFinish={onFinish}
                      validateMessages={validateMessages}
                >
                    {form.schema.map(field => {
                        return (
                            <FormByType
                                key={field.name}
                                field={field}
                            />
                        )
                    })}
                </Form>
            )
        ) : (
            <p style={{textAlign: 'center'}}>No schema provided</p>
        )
    )
};
export default FormRender