import {createContext} from 'react'

function noop() {
}

export const AuthContext = createContext({
    token: null,
    userId: null,
    offices: [],
    currentOffice: null,
    changeOffice: noop,
    role: null,
    login: noop,
    logout: noop,
    isAuthenticated: false
});
